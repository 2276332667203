.app {
    height: 100vh;
  
    /* grid container settings */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header'
      'main'
  }
  
  .app > .header {
    
    grid-area: header;
    max-width: 100vw;

  }
  
  .app > .main {
    grid-area: main;
    overflow:auto;
    max-width: 100vw;
    /* justify-self: center; */

  }
  
  .app > .footer {
    max-width: 100vw;
    height: 146px;

  }
  
  .content {
    overflow: auto;
    max-width: 100vw;
  }
  